import React from 'react';
import {useState} from 'react';

function TypeQuestion(props) {
    var types = ["Normal", "Fire", "Water", "Electric", "Grass", "Ice", "Fighting", "Poison", "Ground",
        "Flying", "Psychic", "Bug", "Rock", "Ghost", "Dragon", "Dark", "Steel", "Fairy"];

    const typesMap = types.map((type) => {
        return <li class={"type-icon type-"+type.toString().toLowerCase()}><label for={type}>{type}</label><input class="typeCheckbox" type="checkbox" id={type}/></li>;
    });

    const [answer, setAnswer] = useState("");

    var pkmnName = props.name;
    var pkmnSprite = "/Pokemon/" + props.sprite;
    console.log(pkmnSprite);

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    function handleClick() {
        const checkedBoxes = Array.from(document.querySelectorAll('input[class=typeCheckbox]:checked'));
        var selectedTypes = [];
        checkedBoxes.map((box) => {
            selectedTypes.push(box.id);
        });
        selectedTypes = selectedTypes.sort();
        console.log(selectedTypes);
        var correct = false;
        if (!props.type2 && selectedTypes.length === 1) {
            correct = (selectedTypes[0] === props.type1);
        }
        else if (props.type2 && selectedTypes.length === 2) {
            correct = (selectedTypes[0] === props.type1 && selectedTypes[1] === props.type2);
        }

        document.querySelectorAll('input[type=checkbox]').forEach( el => el.checked = false );
        if (correct) {
            setAnswer("Correct!");
            sleep(2000).then(() => { setAnswer(""); document.querySelector("button[id=newButton]").click(); });
        }
        else {
            setAnswer("Wrong!");
            sleep(2000).then(() => { setAnswer(""); });
        }
    }

    return (
        <>
            <h1>{pkmnName}</h1>
            <img src={pkmnSprite} />
            <div class="container">
                <ul>
                    {typesMap}
                </ul>
            </div>
            <button onClick={handleClick}>Check</button>
            <h3>{answer}</h3>
        </>
    );
}

TypeQuestion.defaultProps = {
    "type1": "Normal",
    "type2": ""
}

export default TypeQuestion;