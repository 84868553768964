import './App.css';
import React from 'react';
import TypeQuestion from './TypeQuestion';
import {pokemon} from "./data";
import {useState} from 'react';

function App() {
  const [pkmnname, setpkmnname] = useState('Name');
  const [type1, settype1] = useState('Type1');
  const [type2, settype2] = useState('Type2');
  const [sprite, setsprite] = useState('Sprite');

  function handleNewTest() {
    var number = Math.floor(Math.random() * pokemon.length) + 1;
    var pkmn = pokemon[number];
    setpkmnname(pkmn["name"] + (pkmn["form"] ? (" (" + pkmn["form"] + ")") : ""));
    var types = pkmn["types"]
    types = types.sort();
    console.log(types);
    settype1(types[0]);
    settype2(types[1] ? types[1] : "");
    setsprite(pkmn["sprite"]);
    console.log([pkmnname, type1, type2])
  }

  return (
    <div className="App">
      <header className="App-header">
        <TypeQuestion name={pkmnname} type1={type1} type2={type2} sprite={sprite} />
        <button id="newButton" onClick={handleNewTest}>New</button>
      </header>
    </div>
  );
}

export default App;
